<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            default="today"
            label="출근일"
            name="commuteDate"
            v-model="searchParam.commuteDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            type="user" 
            label="사용자" 
            name="userId" 
            v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            :editable="editable"
            :disabled="isVendor"
            label="업체"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-vendor>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="일용직 출퇴근 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'commute-status',
  data() {
    return {
      editable: false,
      searchParam: {
        companyCd: '',
        userId: '',
        vendorCd: '',
      },
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '업체',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '사용자',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'commuteDate',
            field: 'commuteDate',
            label: '출근일',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'startTime',
            field: 'startTime',
            label: '출근시간',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'endTime',
            field: 'endTime',
            label: '퇴근시간',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
    };
  },
  computed: {
    isVendor() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.searchParam.commuteDate = this.$comm.getToday();
      this.editable = this.$route.meta.editable;
      if (this.$store.getters.user.innerFlag === 'N') { 
        this.searchParam.deptCd = this.$store.getters.user.deptCd;
      }
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.mdm.cmt.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
